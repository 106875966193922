<template>
    <div>
        <v-card>
            <v-card-text>
                <div class="text-subtitle-1 d-flex justify-space-between">
                    <div>Egresos {{year}}</div>
                    <page-year
                        :year.sync="year"
                        :maxYear="2015"
                    />
                </div>
                <apexchart
                    type='area'
                    :options="options"
                    :series="series"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    mounted(){
        this.year = this.moment(new Date()).format('YYYY')
    },
    components:{
        'apexchart':VueApexCharts,
        'page-year':()=>import('@/components/com/PageYear'),
    },
    data:()=>({
        year:2022,
        loading:false,
        consultas:[],
        meses:[
            'Ene','Feb','Mar','Abr','May','Jun',
            'Jul','Ago','Sep','Oct','Nov','Dic',
        ],
        options:{},
        series:[],
    }),
    methods:{
        async carga(){
            this.loading=true
            try{
                const req = await this.$http.get('/dashboard/listEgresosYear',{params:{fecha:this.year}})
                this.consultas=req.data.root
                let total = []

                total.push([...req.data.root].filter(egr=>egr.month =='01').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='02').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='03').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='04').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='05').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='06').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='07').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='08').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='09').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='10').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='11').length)//01
                total.push([...req.data.root].filter(egr=>egr.month =='12').length)//01

                this.options={
                    chart:{
                    },
                    tooltip: {
                        enabled:true,
                    },
                    xaxis: {
                        categories: this.meses,
                    },
                    yaxis:{
                        min: 0,
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        formatter(val){
                            return(val)
                        },
                    },
                }

                this.series=[{
                    name: "Egresos",
                    data:total
                }]
                this.loading=false
            } catch(err) {
                this.loading=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    watch:{
        year(){
            this.carga()
        }
    },
}
</script>

<style>

</style>